<template>
  <div class="pa-3">
     <router-link :to="{ name: 'addscma' }">
      <v-btn outlined rounded>
        <v-icon>mdi-plus</v-icon>
        Create Scama
      </v-btn>
    </router-link>
    <br>
      <v-divider class="ma-3"></v-divider>
     
    <h3>{{ $t("scama.names") }}</h3>
    <!-- <div v-if="!dataFetch.length">
      <div justify-space-around class="mt-2">
        <div class="ma-2" v-for="g in 2" :key="g">
          <v-flex xs12 md10 sm10 justify-center>
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-flex>
        </div>
      </div>
    </div> -->
    <div>
      <v-row wrap row justify-space-around>
        <v-col
          cols="12"
          sm="4"
          md="4"
          v-for="(item, items) in dataFetch"
          :key="items"
        >
          
            <v-card elevation="11" color="card" flat class="pa-2" shaped>
              <v-spacer class="mt-2"></v-spacer>
              <div class="text-dark" style="font-size: 13px">
                <!-- style="color:#FB9A17" -->
                <div>
                  <img :src="image" alt="" srcset="" width="50px" height="50px" style="border-radius:50%">
                  <!-- <v-avatar color="maincolor2" size="36">SC</v-avatar> -->
                  <!-- <span class="mdi mdi-account-circle mdi-36px"></span> -->
                  <strong
                    class="mr-3 ml-3"
                    style="font-size: 16px; font-weight: bold"
                    >{{ item.namepage }}</strong
                  >
                  <v-btn class="my-2 mx-2 px-3 " color="red" outlined x-small @click="deleteScama(item.id)"><v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-divider class="mt-3"></v-divider>
                <v-card-text>
                  <v-chip-group
                    v-model="selection"
                    active-class="maincolor2 accent-4 white--text"
                    column
                  >
                    <v-chip
                      @click="
                        cp(
                          `${item.text}?q=${base(
                            `id=sp0mers-team_lang=en_sc=${item.id}_user=${idus}_img=${item.image}`
                          )}`
                        )
                      "
                    >
                      {{ $t("lang.en") }}</v-chip
                    >

                    <v-chip
                      @click="
                        cp(
                          `${item.text}?q=${base(
                            `id=sp0mers-team_lang=ar_sc=${item.id}_user=${idus}_img=${item.image}`
                          )}`
                        )
                      "
                      >{{ $t("lang.ar") }}</v-chip
                    >

                    <v-chip
                      @click="
                        cp(
                          `${item.text}?q=${base(
                            `id=sp0mers-team_lang=th_sc=${item.id}_user=${idus}_img=${item.image}`
                          )}`
                        )
                      "
                      >{{ $t("lang.th") }}</v-chip
                    >

                    <v-chip
                      @click="
                        cp(
                          `${item.text}?q=${base(
                            `id=sp0mers-team_lang=es_sc=${item.id}_user=${idus}_img=${item.image}`
                          )}`
                        )
                      "
                      >{{ $t("lang.es") }}</v-chip
                    >
                  </v-chip-group>
                </v-card-text>
              </div>
              <!-- <div class="views">
          <v-chip
            close-icon="mdi-delete"
            color="orange"
            filter
            link
            outlined
            pill
          >
            {{ views }} <span class="mdi mdi-eye"></span
          ></v-chip>
        </div> -->
            </v-card>
       </v-col
        >
      </v-row>
      <div class="text-center">
        <div v-if="loadings">
          <v-progress-linear
            indeterminate
            color="yellow darken-2"
          ></v-progress-linear>
        </div>
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="more"
                  class="my-4"
                  :length="pages"
                  @input="morepage"
                  :total-visible="10"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <modals
      v-if="modv"
      @close="modv = false"
      :data="modl"
      :itemsss="itemIndex"
      @romoveitem="romoveitems"
    />
  </div>
</template>
<script>
// import Post from "@/components/post/scama.vue";
import Modals from "../lettrs/modalsc.vue";
export default {
  data() {
    return {
      dataFetch: [],
      attrs: "",
      userid: this.$store.getters.getUserId,
      page: 10,
      off: 0,
      mm: 0,
      showProg: false,
      memberadmin: this.$store.getters.getMember,
      // dialog: false,
      pages: "",
      more: 1,
      modv: false,
      modl: null,
      itemIndex: null,
      idus: `${this.$store.getters.getUserId}`,
       config: {
        headers: {
          Accept: "application/json,*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + this.$store.getters.getToken,
        },
      },
    };
  },
  components: {
    // Post,
    Modals,
  },
  methods: {
    base(string) {
      return btoa(string);
    },
    cp: function (param1) {
      let link = `${param1}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.$toastr("add", {
            title: "success",
            msg: this.$t("scama.copy"),
            timeout: 5000,
            position: "toast-top-center",
            type: "success",
            closeOnHover: true,
            clickClose: false,
          });
          // window.location = link
        window.open(link, "_blank");
        })
        .catch((err) => {
          alert(err.message + param1);
        });
    },
    gomod(param, items) {
      this.modl = param;
      this.itemIndex = items;
      this.modv = true;
    },
    morepage: function () {
      this.loadings = true;
      this.$http
        .get("/letter/pagescam/" + this.more, 
       { headers: {
          Accept: "application/json,*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + this.$store.getters.getToken,
          "user":  this.$store.getters.getUserName,
        },}
        )
        .then((res) => {
          this.loadings = false;

          this.dataFetch = res.data.gt;
          this.pages = res.data.pages;
        })
        .catch((err) => {
          console.log(err);
          this.loadings = false;
        });
    },
    dataScama: function () {
      this.$http
        .get("/letter/pagescam/" + this.more, { headers: {
          Accept: "application/json,*/*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: "Bearer " + this.$store.getters.getToken,
          "user":  this.$store.getters.getUserName,
        },})
        .then((res) => {
          this.dataFetch = res.data.gt;
          this.pages = res.data.pages;
        })
        .catch((err) => {
          console.log(err);
        });
    },

        deleteScama: async function(param) {
      this.$http
        .delete(
          "/page/" + this.$store.getters.getUserName + "/" + param,
          this.config
        )
        .then((res) => {
          this.$notify({
            group: "success",
            type: "success",
            title: "success",
            text: res.data.message,
          });
          this.$swal.fire("Succefully", res.data.message, "success");
          this.dataFetch.splice(param, 1);
        })
        .catch((err) => {
          this.$notify({
            group: "errors",
            type: "error",
            title: "error data",
            text: err.message,
          });
          this.$swal.fire("error delete scama", err, "danger");
        });
    },
  },
  beforeMount() {
    this.dataScama();
    // alert(btoa("sjsjskskh"))
  },
};
</script>