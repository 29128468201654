var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('router-link',{attrs:{"to":{ name: 'addscma' }}},[_c('v-btn',{attrs:{"outlined":"","rounded":""}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create Scama ")],1)],1),_c('br'),_c('v-divider',{staticClass:"ma-3"}),_c('h3',[_vm._v(_vm._s(_vm.$t("scama.names")))]),_c('div',[_c('v-row',{attrs:{"wrap":"","row":"","justify-space-around":""}},_vm._l((_vm.dataFetch),function(item,items){return _c('v-col',{key:items,attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"11","color":"card","flat":"","shaped":""}},[_c('v-spacer',{staticClass:"mt-2"}),_c('div',{staticClass:"text-dark",staticStyle:{"font-size":"13px"}},[_c('div',[_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.image,"alt":"","srcset":"","width":"50px","height":"50px"}}),_c('strong',{staticClass:"mr-3 ml-3",staticStyle:{"font-size":"16px","font-weight":"bold"}},[_vm._v(_vm._s(item.namepage))]),_c('v-btn',{staticClass:"my-2 mx-2 px-3 ",attrs:{"color":"red","outlined":"","x-small":""},on:{"click":function($event){return _vm.deleteScama(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-divider',{staticClass:"mt-3"}),_c('v-card-text',[_c('v-chip-group',{attrs:{"active-class":"maincolor2 accent-4 white--text","column":""},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}},[_c('v-chip',{on:{"click":function($event){_vm.cp(
                        ((item.text) + "?q=" + (_vm.base(
                          ("id=sp0mers-team_lang=en_sc=" + (item.id) + "_user=" + _vm.idus + "_img=" + (item.image))
                        )))
                      )}}},[_vm._v(" "+_vm._s(_vm.$t("lang.en")))]),_c('v-chip',{on:{"click":function($event){_vm.cp(
                        ((item.text) + "?q=" + (_vm.base(
                          ("id=sp0mers-team_lang=ar_sc=" + (item.id) + "_user=" + _vm.idus + "_img=" + (item.image))
                        )))
                      )}}},[_vm._v(_vm._s(_vm.$t("lang.ar")))]),_c('v-chip',{on:{"click":function($event){_vm.cp(
                        ((item.text) + "?q=" + (_vm.base(
                          ("id=sp0mers-team_lang=th_sc=" + (item.id) + "_user=" + _vm.idus + "_img=" + (item.image))
                        )))
                      )}}},[_vm._v(_vm._s(_vm.$t("lang.th")))]),_c('v-chip',{on:{"click":function($event){_vm.cp(
                        ((item.text) + "?q=" + (_vm.base(
                          ("id=sp0mers-team_lang=es_sc=" + (item.id) + "_user=" + _vm.idus + "_img=" + (item.image))
                        )))
                      )}}},[_vm._v(_vm._s(_vm.$t("lang.es")))])],1)],1)],1)],1)],1)}),1),_c('div',{staticClass:"text-center"},[(_vm.loadings)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"yellow darken-2"}})],1):_vm._e(),_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.pages,"total-visible":10},on:{"input":_vm.morepage},model:{value:(_vm.more),callback:function ($$v) {_vm.more=$$v},expression:"more"}})],1)],1)],1)],1)],1)],1),(_vm.modv)?_c('modals',{attrs:{"data":_vm.modl,"itemsss":_vm.itemIndex},on:{"close":function($event){_vm.modv = false},"romoveitem":_vm.romoveitems}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }